<template>
    <div class="payFail">
        <div class="navBar">
            <div @click="backPage">
                <svg-icon class="navArrow" iconClass="navArrow" />
            </div>
            <source>
            <div class="navTitle">问题详情</div>
            <div class="rightBtn"></div>
        </div>
        <div class="main">
            <div class="title">支付成功了，但是没有显示成为会员？</div>
            <div class="content">
                请您核实订单是否支付成功，可在开通会员页面右上角点击【账单明细】查看；
            </div>
            <div class="content">
                若订单存在但还不是会员，请尝试退出页面，然后再次进入，会员状态会自动刷新。
            </div>
            <div class="content">
                若您正确操作以上步骤还是不显示会员，请您尝试退出重登的方式。
            </div>
            <div class="content">
                若仍未解决，请您咨询在线客服。
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            // 返回
            backPage() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .payFail {
        height: 100%;
        color: #000;
        background: #f5f5f5;

        .navBar {
            height: 44px;
            padding: 0 12px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;

            .navArrow {
                width: 17px;
                height: 17px;
            }

            .navTitle {
                flex: 2;
                text-align: center;
                font-size: 18px;

            }

            .rightBtn {
                width: 17px;
                height: 17px;
            }
        }

        .main {
            height: calc(100% - 45px);
            overflow-y: auto;
            padding: 0 12px;
            box-sizing: border-box;
            font-size: 14px;

            .title {
                font-weight: 600;
                margin: 12px 0;
                color: #000;
            }

            .content {
                line-height: 26px;
                margin-bottom: 20px;
                font-weight: 400;
                color: #333;
            }
        }
    }
</style>